.list-social {
  display: flex;
  gap: 32px;
  justify-content: center;
  margin-top: 8px;
}

@media (min-width: 697px) {
  .list-social {
    justify-content: flex-start;
    margin-top: 0;
  }
}

.icon-social {
  width: 29px;
  height: 29px;
  fill: #f5f5f5;
  transition: all 0.3s ease 0s;
}

.icon-social:hover {
  fill: #0EB1AF;
  transform: scale(1.1);
}

.lazy-image-container {
  position: relative;
  width: 100%;
  height: 100%;
  background: #f5f5f5;
  overflow: hidden;
}

.lazy-image-placeholder {
  width: 100%;
  height: 100%;
  background: #f5f5f5;
}

.lazy-image-loading-indicator {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.spinner {
  width: 30px;
  height: 30px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #0EB1AF;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.lazy-image-loading {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.lazy-image-loaded {
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

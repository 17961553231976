.section-tasks {
  margin-top: 95px;
  padding: 20px 0px;
  background: #F2F3F4;
  
}

.headerContainer-tasks {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 50px;
}

.title-challenges-tasks {
  color: #032741;
  text-align: center;
  font-size: 20px;
  margin-bottom: 20px;
  font-family: "Gibson", "sans-serif";
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: 0.01em;
  margin-top: 20px;
}

@media (min-width: 480px) {
  .title-challenges-tasks {
    margin-bottom: 32px;
    font-size: 32px;
  }
}

@media (min-width: 768px) {
  .title-challenges-tasks {
    font-size: 40px;
  }
}

.text-tasks {
  max-width: 920px;
  padding: 0px;
  color: #032741;
  border: 0;
  border-radius: 16px;
  text-align: center;
  margin-bottom: 0px;
  font-size: 20px;
}

@media (max-width: 768px) {
  .text-tasks {
    font-size: 18px;
  }
}

@media (max-width: 420px) {
  .text-tasks {
    font-size: 14px;
    margin-bottom: 20px;
  }
}

.list-tasks {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 20px;
  margin: 20px auto;
  color: black;
}

@media (min-width: 600px) {
  .list-tasks {
    grid-template-columns: 1fr 1fr;
    row-gap: 32px;
    margin: 32px auto;
    max-width: 1366px;
  }
}

@media (min-width: 1200px) {
  .list-tasks {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    row-gap: 56px;
    margin: 56px auto;
    max-width: 1680px;
  }
}



.task-group-cardContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  height: fit-content;
  border-bottom: 1px solid #0EB1AF;
  padding: 20px;
  
}

@media (min-width: 768px) {
  .task-group-cardContainer {
    flex-direction: row;
    align-items: center;
    gap: 24px;
  }
}

@media (min-width: 1200px) {
  .task-group-cardContainer {
    gap: 48px;
  }
}

.iconWrapper {
  border-radius: 50%;
  width: 90px;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;

}
@media (min-width: 768px) {
  .iconWrapper{
    width: 120px;
    height: 120px;
  }
}
@media (min-width: 1200px) {
  .iconWrapper{
    width: 160px;
    height: 160px;
  }
}

.task-group-header {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: 0.01em;
  color: #FFFFFF;

}


@media (min-width: 1920px) {
  .task-group-header {
    font-size: 24px;
  }
}

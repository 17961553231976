.menu {
  color: white;
  display: flex;
  font-family: "Gibson", sans-serif;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0;
  font-size: 20px;
  margin-bottom: 0;
  align-items: center;
  column-gap: 32px;
  row-gap: 10px;
  flex-wrap: wrap;
}

@media (max-width: 1024px) {
  .menu {
    display: none;
  }
}

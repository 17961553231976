.header .logo-header {
  display: block;
  width: 60px;
  height: 60px;

}

@media (min-width: 1025px) {
  .header .logo-header {
    width: 180px;
    height: 180px;
  }
}

.footer .logo-header {
  display: block;
  width: 120px;
  height: 120px;

}

@media (max-width: 696px) {
  .footer .logo-header {
    width: 100px;
    height: 100px;
  }
}

.section-toddlers {
  background: #f5f5f5; 
  margin-top: 95px;
  padding-bottom: 48px;
  padding-top: 10px;

}

@media (min-width: 1025px) {
  .section-toddlers {
    margin-top: 105px;
  }
}

.headerContainer {
  display: flex;
  flex-direction: column;
  padding: 0px 50px;
}

.wrapper-toddlers {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top:40px;
}

.toddlersContainer {
  margin: 0% 20px;
  padding: 20px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}

.title-toddlers {
  color: #032741;; 
  text-align: center;
  font-size: 20px;

  font-family: "Gibson", "sans-serif";
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: 0.01em;
}

@media (min-width: 480px) {
  .title-toddlers {
    font-size: 32px;
  }
}

@media (min-width: 768px) {
  .title-toddlers {
    font-size: 40px;
  }
}


.content-toddlers {
  display: grid;
  gap: 20px;
  margin-bottom: 20px;
  grid-template-columns: 1fr;

}

@media (min-width: 550px) {
  .content-toddlers {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1280px) {
  .content-toddlers {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (min-width: 1500px) {
  .content-toddlers {
    grid-template-columns: repeat(4, 1fr);
  }
}

.search-toddlers {
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  .search-toddlers {
    margin-bottom: 48px;
  }
}

@media (min-width: 1440px) {
  .search-toddlers {
    margin-bottom: 72px;
  }
}

.loadMore {
  align-self: center;
}

.loadMore.hidden {
  display: none;
}

.fullHeight {
  min-height: calc(100vh - 160px);
}

.text {
  max-width: 556px;
  padding: 20px;
  color:  #032741;; 
  border: 1px solid #F9B200;
  border-radius: 16px;
  text-align: center;
  margin-bottom: 32px;
  font-size: 20px;
}

@media (max-width: 768px) {
  .text {
    font-size: 18px;
  }
}

@media (max-width: 420px) {
  .text {
    font-size: 14px;
    margin-bottom: 20px;
  }
}


.titleDots{
  display:flex;
  flex-direction:row;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
}
.toddlers i {
    color:  #6B377C;
}


.main-category i:nth-child(1) {
  font-size: 15px;
  margin-left: 30px;
}

.main-category i:nth-child(2) {
  font-size: 10px;
}

.main-category i:nth-child(3) {
  font-size: 5px;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 40px 0;
}

.spinner {
  width: 50px;
  height: 50px;
  border: 5px solid #f5f5f5;
  border-top: 5px solid #F9B200;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Base Button Container */
.Button {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Modern Button Base Styles */
.modern-button {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: Gibson-Bold, sans-serif;
  border: none;
  border-radius: 5px; /* Subtle rounding */
  padding: 10px 20px; /* Adjusted padding */
  margin-top: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease; /* Simple transition */
  background: linear-gradient(135deg, #cde8e8 0%, #30b8b7 100%); /* Simple gradient */
  color: #ffffff; /* Text color */
}

/* Hover Effects */
.modern-button:hover {
  background: linear-gradient(135deg, #30b8b7 0%, #1a9e9d 100%); /* Darker gradient on hover */
}

/* Active State */
.modern-button:active {
  background: linear-gradient(135deg, #1a9e9d 0%, #176968 100%); /* Even darker on active */
}

/* Focus State */
.modern-button:focus {
  outline: 2px solid #30b8b7; /* Clear focus outline */
}

/* Disabled State */
.modern-button:disabled {
  background: #d3d3d3; /* Grey background for disabled */
  color: #a9a9a9; /* Grey text */
  cursor: not-allowed; /* Not allowed cursor */
}

/* Button Icon */
.modern-button .button-icon {
  width: 24px; /* Adjusted icon size */
  height: 24px; /* Adjusted icon size */
  margin-bottom: 0.5rem; /* Adjusted margin */
}

/* Category Text */
.modern-button .CategoryButton {
  width: 100%;
  padding: 0.5rem 0; /* Adjusted padding */
  margin: 0;
  font-size: 1rem; /* Adjusted font size */
  text-transform: uppercase;
  letter-spacing: 0.025em;
}

/* Description Text */
.Button .description {
  margin-top: 0.5rem; /* Adjusted margin */
  font-family: Gibson-Regular, sans-serif;
  font-size: 0.875rem; /* Adjusted font size */
  line-height: 1.5;
  color: #333;
  text-align: center;
  max-width: 200px;
}

/* Responsive Design */
@media (min-width: 768px) {
  .modern-button {
    padding: 12px 24px; /* Adjusted padding for larger screens */
  }
}

@media (min-width: 1200px) {
  .modern-button {
    padding: 14px 28px; /* Further adjusted padding for larger screens */
  }
}

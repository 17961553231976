.article-page {
  padding: 2rem;
  padding-top: 120px; /* Add extra padding at the top to account for the header */
  max-width: 1200px;
  margin: 0 auto;
  min-height: calc(100vh - 200px);
}

.article-page-container {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 2rem;
}

.article-page-content {
  max-width: 800px;
  margin: 0 auto;
}

.article-page h1 {
  color: #333;
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  text-align: center;
}

.article-page h2 {
  color: #444;
  font-size: 2rem;
  margin: 2rem 0 1rem;
}

.article-page h3 {
  color: #555;
  font-size: 1.5rem;
  margin: 1.5rem 0 1rem;
}

.article-page-image-container {
  margin: 2rem 0;
  text-align: center;
}

.article-page-image {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.article-page-text {
  line-height: 1.6;
  color: #333;
}

.article-page-text p {
  margin-bottom: 1.5rem;
}

.article-page-text ul,
.article-page-text ol {
  margin: 1rem 0 1.5rem 2rem;
}

.article-page-text li {
  margin-bottom: 0.5rem;
}

.article-page-date {
  margin-top: 2rem;
  text-align: right;
  color: #666;
  font-style: italic;
}

.article-page-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 200px);
  padding-top: 120px; /* Add padding here too */
}

.spinner {
  width: 50px;
  height: 50px;
  border: 5px solid #f3f3f3;
  border-top: 5px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media (max-width: 768px) {
  .article-page {
    padding: 1rem;
    padding-top: 100px; /* Slightly less padding on mobile */
  }

  .article-page-container {
    padding: 1rem;
  }

  .article-page h1 {
    font-size: 2rem;
  }

  .article-page h2 {
    font-size: 1.75rem;
  }

  .article-page h3 {
    font-size: 1.25rem;
  }
}

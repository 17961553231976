.link {
  position: relative;
  padding: 3px 0;
  color: #fff; 
  transition: all 0.3s ease; 
  text-decoration: none;

}

.link:hover {
  color: #F9B200;
}

.link--modal {
  text-decoration: underline;
}

.link--default {
  position: relative;
}

.link--default::before {
  content: "";
  width: 0;
  height: 2px;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color:rgb(14, 107, 184); 
  transition: all 0.3s ease;
}

.link--default:hover {
  color:rgb(5, 64, 63); 
}

/* Active state for all link types */
.link.active {
  color: #F9B200 !important;
  text-decoration: none;
}

/* Remove underline for active links */
.link.active::before {
  width: 0 !important;
}

.link--help {
  border-bottom: 1px solid #F2F3F4;

}

.link--help:hover,
.link--help:focus {
  border-bottom: 1px solid  #F9B200;
}

.link--help:active {
  border-bottom: 1px solid #F2F3F4;
}

@media (max-width: 696px) {
  .link--help,
  .link--structure,
  .link--footer,
  .link--docs {
    font-size: 14px;
  }
}

.link--structure {
  border-bottom: 1px solid #F2F3F4;

}

.link--structure:hover,
.link--structure:focus {
  border-bottom: 1px solid #F2F3F4;

}

.link--structure:active {
  border-bottom: 1px solid #F2F3F4;

}

.link--footer:active,
.link--docs:active {
  text-decoration: underline;
}

.link--burger {
  font-size: 20px;
  position: relative;
  display: block;
  font-weight: 700;
  line-height: 1.2;
  padding-left: 14px;
  height: 100%;
}

.link--burger.active {
  color: #F9B200;
}

.link--burger.active::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 2px;
  height: 100%;
  background-color: #ff6600;
}

.link--card {
  font-size: 16px;
  font-weight: 400;
  position: relative;
  padding: 10px 4px;
  display: block;
  color: #fff; 
}

.link--card::before {
  content: "";
  width: 0;
  height: 1px;
  background-color: #ccc; 
  position: absolute;
  bottom: 0;
  left: 0;
  transition: width 0.3s ease;
}

.link--card:hover {
  color: #fff;
}

.link--card:hover::before {
  width: 100%;
}

.link--blog {
  position: relative;
  padding: 11.5px 4px;
  display: block;
  color: #000; 
}

.link--blog::before {
  content: "";
  width: 0;
  height: 1px;
  background-color: #ccc;
  position: absolute;
  bottom: 0;
  left: 0;
  transition: width 0.3s ease;
}

.link--blog:hover::before {
  width: 100%;
}

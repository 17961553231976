.material-card {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.2s ease-in-out;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.material-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.material-image-container {
  width: 100%;
  height: 200px;
  overflow: hidden;
  position: relative;
}

.material-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.material-card:hover .material-image {
  transform: scale(1.05);
}

.material-content {
  padding: 1.5rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.material-title {
  color: #333;
  font-size: 1.25rem;
  margin: 0 0 1rem 0;
  line-height: 1.4;
  font-weight: 600;
}

.material-description {
  color: #666;
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 1.5rem;
}

.download-link {
  display: inline-block;
  color: #0EB1AF;
  text-decoration: none;
  font-weight: 500;
  margin-top: auto;
  transition: color 0.2s ease;
  padding: 8px 16px;
  border: 2px solid #0EB1AF;
  border-radius: 20px;
  text-align: center;
}

.download-link:hover {
  color: #fff;
  background-color: #0EB1AF;
}

@media (max-width: 768px) {
  .material-image-container {
    height: 180px;
  }

  .material-content {
    padding: 1rem;
  }

  .material-title {
    font-size: 1.1rem;
    margin-bottom: 0.75rem;
  }

  .material-description {
    font-size: 0.9rem;
    margin-bottom: 1rem;
  }
}

.container-card {
  border: 5px solid #0EB1AF;
  border-radius: 50%; 
  position: relative;
  overflow: hidden;
  width: 250px; 
  height: 250px;
}

@media (min-width: 810px) {
  .container-card {
    padding: 74px;
    width: 350px; 
    height: 350px;
  }
}

.container-card::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 6;
  top: 0;
  left: 0;
  background: linear-gradient(222deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.6) 100%);
  transition: all 0.3s ease 0s;
}

.container-card:hover::before {
  opacity: 0;
  pointer-events: none;
}

.container-card.hideBg::before {
  opacity: 0;
  pointer-events: none;
}

.imgContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 50%; 
  display: flex; 
  justify-content: center; 
  align-items: center; 
}

.imgContainer > img {
  object-fit: cover; 
  width: 100%; 
  height: 100%; 
  border-radius: 50%;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 0px;
  position: relative;
  z-index: 6;
  justify-content: space-between;
  opacity: 1;
  transition: opacity 0.3s ease 0s;
}

@media (min-width: 810px) {
  .content {
    gap: 50px;
  }
}

.content.hidden {
  opacity: 0;
}

.title-card {
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: 0.01em;
  font-size: 32px;
  margin: 50px 0;
  color: white;
  background: transparent;
  border: none; 
  outline: none;
}

@media (min-width: 810px) {
  .title-card {
    font-size: 24px;
  }
}
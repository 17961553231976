.article-section {
  padding: 40px 0;
  background-color: #F2F3F4;
}

.article-section-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.article-section-title {
  width: 100%;
  margin-bottom: 32px;
  text-align: center;
  font-family: 'Gibson', sans-serif;
  font-weight: 700;
  font-style: italic;
}

.article-section-title h2 {
  font-size: 40px;
  color: #032741;
}

.article-section-content {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 32px;
  margin: 0 auto;
}

/* Loading state styles */
.loading-spinner {
  grid-column: 1 / -1;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
}

.loading-placeholder {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 32px;
  width: 100%;
}

.article-placeholder {
  background: #fff;
  border-radius: 8px;
  height: 300px;
  animation: pulse 1.5s infinite;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
}

@keyframes pulse {
  0% {
    opacity: 0.6;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0.6;
  }
}

@media (max-width: 768px) {
  .article-section-content,
  .loading-placeholder {
    grid-template-columns: 1fr;
    gap: 24px;
  }
  
  .article-placeholder {
    height: 250px;
  }
}

.mentor {
  padding: 22px 10px;
}

@media (min-width: 768px) {
  .mentor {
    padding: 100px 20px 0px 20px;
  }
}

@media (min-width: 1920px) {
  .mentor {
    padding: 100px 20px 0px 20px;
    margin: 20px;
  }
}

.mentor-title {
  text-align: center;
  font-weight: 700;
  font-size: 26px;
}

@media (min-width: 768px) {
  .mentor-title {
    font-weight: 600;
    font-size: 32px;
    margin-bottom: 20px;
  }
}

@media (min-width: 1280px) {
  .mentor-title {
    font-size: 36px;
    margin-bottom: 48px;
  }
}

.mentor-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mentor-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
}

@media (min-width: 1280px) {
  .mentor-wrapper {
    flex-direction: row;
    align-items: flex-end;
  }
}

.mentor-text {
  padding: 10px;
  width: 100%;
  max-width: 900px;
  margin-bottom: 10px;
  position: relative; 
  z-index: 1;
  text-align: center;
}

@media (min-width: 1268px) {
  .mentor-text {
    left: 0;
    margin: 0 auto;
  }
}

.mentor-text-mb {
  margin-bottom: 21px;
}

@media (min-width: 768px) {
  .mentor-text-mb {
    margin-bottom: 30px;
  }
}

.mentor-wrapper-img {
  position: relative;
  width: clamp(188px, 92vw, 573px);
  height: clamp(182px, 87vw, 480px);
}

@media (min-width: 1280px) {
  .mentor-wrapper-img {
    position: relative;
    margin-top: -50px; 
    z-index: 2;
  }
}

.mentor-image {
  display: none;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.mentor-image-mobile {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

@media (min-width: 1280px) {
  .mentor-image {
    display: block;
  }

  .mentor-image-mobile {
    display: none;
  }
}

.mentor-btn {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.videoContainer {
  background: transparent;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5% 15%; 
  width: 100%;
  height: 100%;
}

.textContainer {
  color: #032741;
  font-size: 44px !important;
  letter-spacing: 0.01em;
  font-family: "gibson-bold", sans-serif;
  line-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 90px;
  margin-bottom: 10px;
  text-align: center;
}

.responsiveImage {
  width: 100%;
  padding: 0px 30px;
}

.mainHeader {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: #032741;
  color: white;
  padding: 12.5px 0;
}

@media (min-width: 1025px) {
  .mainHeader {
    padding: 18px 0;
  }
}

.wrapperHeader {
  max-width: 1680px;
  margin: 0 10%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  padding: 0 10px;
  position: relative;
  z-index: 1001;
}

@media (min-width: 1281px) {
  .wrapperHeader {
    padding: 0 40px;
  }
}


.logo-header {
  z-index: 9;
  width: 150px;
}

.actions {
  display: flex;
  align-items: center;
  gap: 20px;
}

.actions > * {
  flex-shrink: 0;
}

@media (min-width: 1281px) {
  .actions {
    gap: 48px;
  }
}

.headerBtn {
  display: none;
}

@media (min-width: 1025px) {
  .headerBtn {
    display: block;
  }
}

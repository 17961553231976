@media (max-width: 1024px) {
  .navbar {
    z-index: 999;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    background-color: #032741;
    width: 100%;
    top: 0;
    padding: 0 10vw;
    transition: 0.5s height;
    font-family: Gibson-Regular;
  }
}

@media (min-width: 1025px) {
  .navbar,
  .navbar .navigationMain,
  .navbar .contentBurger {
    display: none !important;
  }
}

.navbar .navigationMain {
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  padding: 1rem 30px;
  width: calc(100% - 30vw);
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.navbar .navigationMain .button {
  background-color: transparent;
  border: none;
  font-size: 1rem;
  cursor: pointer;
  color: white;
  transition: 0.5s color;
}

.navbar .navigationMain .burgerMenuContainer {
  width: 55px;
  height: 25px;
  position: relative;
  right: 0;
  cursor: pointer;
}

.navbar .navigationMain .burgerMenuTrigger {
  width: 55px;
  height: 25px;
  position: absolute;
  right: 0;
  cursor: pointer;
  z-index: 100;
}

.navbar .navigationMain .burgerMenu {
  background-color: white;
  position: absolute;
  width: 25px;
  height: 2px;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  transition: 0.25s all ease-in-out;
}

.navbar .navigationMain .burgerMenu::after,
.navbar .navigationMain .burgerMenu::before {
  content: "";
  background-color: white;
  position: absolute;
  width: 25px;
  height: 2px;
  transition: 0.25s all ease-in-out;
}

.navbar .navigationMain .burgerMenu::before {
  transform: translateY(-8px);
}

.navbar .navigationMain .burgerMenu::after {
  transform: translateY(8px);
}

.navbar.active .navigationMain .burgerMenu {
  background-color: transparent;
}

.navbar.active .navigationMain .burgerMenu::before {
  transform: rotate(45deg);
}

.navbar.active .navigationMain .burgerMenu::after {
  transform: rotate(-45deg);
}

.navbar .contentBurger {
  position: fixed;
  top: 60px;
  left: 0;
  width: 100%;
  height: calc(100vh - 60px);
  background-color: #032741;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s all ease-in-out;
}

.navbar.active .contentBurger {
  opacity: 1;
  visibility: visible;
}

.navbar .contentBurger ul {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: center;
}

.navbar .contentBurger ul li {
  padding: 1rem;
  opacity: 0;
  transform: translateY(20px);
  transition: 0.3s all ease-in-out;
}

.navbar.active .contentBurger ul li {
  opacity: 1;
  transform: translateY(0);
}

.navbar .contentBurger ul li a {
  font-size: 1.5rem;
  color: white;
  text-decoration: none;
  transition: 0.25s color;
}

.navbar .contentBurger ul li a:hover {
  color: #D1D6DA;
}

@media (max-width: 768px) {
  .navbar .contentBurger ul li a {
    font-size: 1.2rem;
  }
}

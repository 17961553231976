.btn_main {
  display: inline-block; /* Ensure it is displayed */
  position: relative;
  color: #ffffff; 
  padding: 10px 20px; /* Adjusted padding */
  border-radius: 5px; /* Subtle rounding */
  cursor: pointer;
  background: linear-gradient(135deg, #cde8e8 0%, #30b8b7 100%); /* Simple gradient */
  border: none; /* No border */
  transition: background-color 0.3s ease; /* Simple transition */
}

.btn_main:hover {
  background: linear-gradient(135deg, #30b8b7 0%, #1a9e9d 100%); /* Darker gradient on hover */
}

.btn_main:active {
  background: linear-gradient(135deg, #1a9e9d 0%, #176968 100%); /* Even darker on active */
}

.btn_main:disabled {
  background: #d3d3d3; /* Grey background for disabled */
  color: #a9a9a9; /* Grey text */
  cursor: not-allowed; /* Not allowed cursor */
}

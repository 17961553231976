.section-footer {
  padding: 48px 20px;
  background-color: #032741; 
  font-family: "Gibson", sans-serif;
  font-size: 18px;
}

.block-footer {
  margin: 0 auto;
  padding: 0 20px;
  max-width: 1200px;
}

.footer {
  margin: auto;
  padding: 12px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  gap: 40px;
}

.footer .logo {
  width: 120px;
  height: auto;
  align-self: flex-start;
}

@media (min-width: 1025px) {
  .footer {
    margin: auto;
    padding: 8px 0;
  }
}

.contacts-footer {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: auto;
  align-items: flex-start;
  margin-left: auto;
}

@media (max-width: 1024px) {
  .contacts-footer {
    width: 100%;
    margin-top: 48px;
    align-items: center;
  }
}

.positionContacts {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.smallScreen {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 20px;
  gap: 32px;
  align-items: center;
}

.smallScreen .logo {
  width: 140px;
  height: auto;
}

@media (min-width: 768px) {
  .smallScreen {
    display: none;
  }
}

.bigScreen {
  display: none;
}

@media (min-width: 768px) {
  .bigScreen {
    display: block;
  }
}

.centerItem {
  display: flex;
  justify-content: center;
}

.logoWrapper-footer {
  background-color: #032741; 
  color: white;
  display: block;
  margin: 40px auto 0;
  max-width: 200px;
  height: auto;
}

@media (min-width: 768px) {
  .logoWrapper-footer {
    max-width: 250px;
  }
}

.section {
  margin-top: 80px;
  position: relative;
  background: #F2F3F4;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.videoContainer {
  background: transparent;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5% 15%; 
  width: 100%;
  height: 100%;
}

.textContainer {
  color: #032741;
  font-size: 40px;
  letter-spacing: 0.01em;
  font-weight: 600;
  line-height: 150%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 90px;
  margin-bottom: 56px;
  text-align: center;
  font-family: gibson, sans-serif;
  opacity: 0;
  transform: translateY(20px); 
  animation: fadeIn 3.5s ease-in forwards; 

}

@media (min-width: 1200px) {
  .textContainer {
  font-size: 50px;
}
}
@keyframes fadeIn {
  from {
    opacity: 0; 
    transform: translateY(20px);
  }
  to {
    opacity: 1; 
    transform: translateY(0); 
  }
}
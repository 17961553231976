.list-links {
  width: 100px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.list-links > *:not(:last-child) {
  margin-bottom: 16px;
}

@media (min-width: 697px) {
  .list-links {
    width: 130px;
  }
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(10, 10, 10, 0.5);
  z-index: 1000;

}
.modaScroll{
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  height: 100%;
  width: 100%;
}
.roleSection-categories {
  background: #F2F3F4;
  padding: 16px 0;
}

@media (min-width: 420px) {
  .roleSection-categories {
    padding: 20px 60px;
  }
}

@media (min-width: 768px) {
  .roleSection-categories {
    padding: 2px 120px;
  }
}

.container-categories {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  padding: 0 40px;
  margin-bottom: 20px;
}

@media (min-width: 420px) {
  .container-categories {
    gap: 16px;
  }
}

@media (min-width: 768px) {
  .container-categories {
    gap: 24px;
  }
}

.title {
  font-family: gibson,sans-serif;
  font-weight: bold; 
  font-size: 20px;
}

@media (min-width: 420px) {
  .title {
    font-size: 32px;
  }
}

@media (min-width: 768px) {
  .title {
    font-size: 40px;
  }
}

.content-categories {
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;
  position: relative;
  width: 100%;
}

.dino-image {
  display: none;
  position: absolute;
  right: -80px;
  top: -50px;
  width: clamp(188px, 30vw, 300px);
  height: clamp(182px, 30vw, 300px);
  object-fit: contain;
  z-index: 2;
}

@media (min-width: 1280px) {
  .dino-image {
    display: block;
  }
}

@media (min-width: 992px) {
  .dino-image {
    right: -170px;
    top: calc(66.67% - 340px);
  }
}

@media (min-width: 600px) {
  .content-categories {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 768px) {
  .content-categories {
    gap: 20px;
  }
}

@media (min-width: 992px) {
  .content-categories {
    grid-template-columns: repeat(3, 1fr);
  }
}

.mobContainer {
  max-width: 100%;
}

.article-section-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.boxBtn {
  position: relative;
  font-family: gibson,sans-serif;
  font-weight: 400;
  cursor: pointer;
  border-radius: 5px;
  z-index: 1002;
}

.btn_lang {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 8px 12px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  min-width: 70px;
  background-color: #032741;
  color: white;
}

.btn_lang._active,
.btn_lang:hover {
  background-color: #043b61;
}

.flag_icon {
  width: 20px;
  height: 20px;
  object-fit: cover;
  border-radius: 2px;
}

.btn_icon {
  display: flex;
  align-items: center;
  transition: transform 0.3s ease;
}

.btn_icon img {
  width: 20px;
  height: 20px;
}

.btn_icon_up {
  transform: rotate(180deg);
}

.options {
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
  width: 100%;
  background-color: #032741;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  z-index: 1002;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.options_item_btn {
  width: 100%;
  padding: 8px 12px;
  border: none;
  background: none;
  color: white;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.3s ease;
  font-family: gibson,sans-serif;
}

.materials-section {
  background: #f5f5f5;
  margin-top: 95px;
  padding: 48px 20px;
}

.materials-container {
  max-width: 1200px;
  margin: 0 auto;
}

.materials-title {
  color: #333;
  font-size: 2rem;
  text-align: center;
  margin-bottom: 2rem;
  font-family: "Gibson", sans-serif;
  font-weight: bold;
}

.materials-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
}

@media (max-width: 1024px) {
  .materials-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .materials-section {
    margin-top: 85px;
    padding: 32px 16px;
  }

  .materials-title {
    font-size: 1.75rem;
    margin-bottom: 1.5rem;
  }
}

@media (max-width: 480px) {
  .materials-grid {
    grid-template-columns: 1fr;
  }

  .materials-section {
    padding: 24px 12px;
  }

  .materials-title {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
}

.wrapper-modal {
  padding: 16px 16px 80px 16px;
  margin: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  
}

@media (min-width: 768px) {
  .wrapper-modal {
    padding: 40px 16px 80px 16px;
  }
}

@media (min-width: 1200px) {
  .wrapper-modal {
    padding: 10vh 16px;
  }
}

 .modal {
  border: 2px solid #0EB1AF;
  min-width: 270px;
  background: #032741; 
  padding: 44px 18px 24px 18px;
  border-radius: 12px;
  border-width: 1px;
  border-style: solid;
  border-color:  #0EB1AF; 
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow-y: auto;  
  max-height: 80vh;  
  scroll-behavior: smooth;  
  &::-webkit-scrollbar {
    width: 15px;  
    border-color: #F9B200;

  }
  &::-webkit-scrollbar-track {
    background: #f0f0f0;  
    width: 15px;  
    border-color: #F9B200;

  }

  &::-webkit-scrollbar-thumb {
    width: 15px;  
    border-color: #F9B200;

  }
}

@media (min-width: 768px) {
  .modal {
    padding: 10%;
    min-width: 500px;

  }
}

@media (min-width: 1200px) {
  .modal {
    min-width: 700px;
  }
}

.closeBtn {
  background: white;
  margin-bottom: 8%;
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;

}

@media (min-width: 768px) {
 .closeBtn {
    top: 10px;
    right: 18px;

  }
}

.title {
  text-align: center;
  width: 100%;
  font-size: 24px;
  font-weight: 600;
  line-height: 150%;
  color: #F2F3F4; 
}

@media (min-width: 768px) {
  .title {
    font-size: 32px;
  }
}

@media (min-width: 1200px) {
   .title {
    font-size: 40px;
  }
}

.modal-card {
  width: 100%;
}
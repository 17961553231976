
.wrapper-input {
  position: relative;
  height: 44px;
  max-width: 625px;
}


.wrapper-input input {
  font-size: 12px;
  outline: none; 
  border: none;
  width: 100%;
  height: 100%;
  padding: 0 10px;
  padding-right: 68px;
  border-radius: 8px;
}



@media (min-width: 768px) {
  .wrapper-input input {
    font-size: 16px;
  }
}


.close {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  padding: 3px;
  top: 50%;
  right: 50px;
  transform: translateY(-50%);
  transition: all 0.3s ease;
  opacity: 0;
  pointer-events: none;
  outline: none; 
  border: none;
  background: transparent;

}

.close.show {
  opacity: 1;
  pointer-events: all;

}


.submit {
  height: 100%;
  width: 44px;
  position: absolute;
  top: 0;
  right: 0;
  outline: none; 
  border: none;
  background: transparent;
}

.contacts-links {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.contact-link {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.contact-link img {
  width: 25px;
  height: auto;
  flex-shrink: 0;
}

.text-link {
  font-family: "Gibson", sans-serif;
  font-weight: 400; 
  color: #f5f5f5; 
  font-size: 14px;
  margin-left: 20px;
  user-select: text;
  -moz-user-select: text;
  -webkit-user-select: text;
  -ms-user-select: text;
}

@media (min-width: 697px) {
  .text-link {
    font-weight: bold; 
  }
}

.mail-link {
  stroke: #f5f5f5;
}

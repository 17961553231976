:root {
  --hover: #0056b3;  /* A darker shade for hover effect */
}

.article-categories {
  width: 100%;
  border-radius: 8px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  background: rgb(0, 116, 172);
  position: relative;
  overflow: hidden;
}
.imageContainer-categories {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  width: 150px;
  height: 150px;
  margin: 0 auto;
  margin-top: 31px;
}

.imageContainer-categories img {
  max-width: 100%;
  max-height: 100%;
  width: 150px;
  height: 150px;
  object-fit: contain;
}
.card-content {
  display: flex;
  flex-direction: column;
  color: white;
}

.heading {
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  height: 70px;
  background: transparent;
  gap: 15px;
  line-height: 1.3;
  letter-spacing: 0.01em;
  font-family: "Gibson", sans-serif;
  font-weight: 700;
}

@media (min-width: 500px) {
  .heading {
    font-size: 26px;
  }
}

@media (min-width: 768px) {
  .heading {
    font-size: 32px;
  }
}

@media (min-width: 900px) {
  .heading {
    font-size: 30px;
  }
}

@media (min-width: 1366px) {
  .heading {
    font-size: 28px;
  }
}

.shortDesc {
  display: -webkit-box;
  overflow: hidden;
  margin-bottom: 0px;
  padding: 0px 24px 24px 24px;
  font-family: "Gibson", sans-serif;
  font-weight: 400;
  font-size: 18px;
}

.card-button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: var(--font-m); 
  font-weight: 500;
  text-transform: uppercase;
  text-align: center;
  z-index: 9;
  transition: all 0.3s;
}

.card-button:hover {
  background-color: var(--hover); 
}

@media (min-width: 1024px) {
  .card-button {
    right: 0px;
    bottom: 0%;
  }
}

@media (min-width: 1280px) {
  .card-button {
    width: 100%;
    height: 100px;
  }
}

.arrowIcon {
  width: 30px;
  height: 30px;
}

@keyframes pulse {
  0%, 100% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.1);
    opacity: 0.9;
  }
}
